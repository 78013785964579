import React, { lazy, Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Global, ThemeProvider, CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import ToastMessage from "components/utils/ToastMessgae";
import ErrorBoundary from "components/errors/ErrorBoundary";
import Loading from "components/utils/Loading";
import apolloClient from "includes/apollo";
import { route } from "includes/routes";
import { getSiteTitle } from "config";
import { AppState } from "core/contexts/AppContext";
import theme from "./theme";

/**
 * Ensures loading each component takes at least 1s for consistency, better experience than
 * quick white flash.
 *
 * @param orig
 */
const lazyLoad = (orig: any) =>
    lazy(
        (): Promise<any> =>
            Promise.all([
                orig(),
                new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                }),
            ]).then(([module]) => module),
    );

const Dashboard = lazyLoad(() => import("./pages/dashboard/Dashboard"));
const Imports = lazyLoad(() => import("./pages/imports/Imports"));
const Reports = lazyLoad(() => import("./pages/reports/Reports"));
const User = lazyLoad(() => import("./core/components/auth/User"));
const Schools = lazyLoad(() => import("./pages/schools/Schools"));
const Users = lazyLoad(() => import("./pages/users/Users"));
const DevTasks = lazyLoad(() => import("./pages/devTasks/DevTasks"));
const Screen = lazyLoad(() => import("./core/components/layout/Screen"));
const Calculators = lazyLoad(() => import("./pages/calculators/Calculators"));
const WritingCalculators = lazyLoad(
    () => import("./pages/writing-calculators/WritingCalculators"),
);
const SchoolReports = lazyLoad(
    () => import("./pages/schoolReports/SchoolReports"),
);
const GrantAccess = lazyLoad(() => import("./pages/grantAccess/GrantAccess"));
const GrantConsumption = lazyLoad(
    () => import("./pages/grantConsumption/GrantConsumption"),
);

const DEFAULT_STATE = {
    user: null,
};

/**
 * Set compat to prevent first/last/nth child css errors.
 */
const emotionCache = createCache({
    key: "hq-emotion-cache",
});
emotionCache.compat = true;

function App() {
    return (
        <CacheProvider value={emotionCache}>
            <HelmetProvider>
                <ApolloProvider client={apolloClient}>
                    <AppState initialState={DEFAULT_STATE}>
                        <ThemeProvider theme={theme}>
                            <Global styles={theme.root} />

                            <ErrorBoundary>
                                <Helmet>
                                    <title>{getSiteTitle("")}</title>
                                </Helmet>

                                <ToastMessage />

                                <Suspense
                                    fallback={
                                        <Loading
                                            delay={300}
                                            fitToScreen
                                            onTop
                                            overlay={false}
                                        />
                                    }
                                >
                                    <BrowserRouter>
                                        <Screen>
                                            <User>
                                                <Routes>
                                                    <Route
                                                        path={`${route(
                                                            "reports",
                                                        )}/*`}
                                                        element={<Reports />}
                                                    />
                                                    <Route
                                                        path={route(
                                                            "schoolReports",
                                                        )}
                                                        element={
                                                            <SchoolReports />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "imports",
                                                        )}/*`}
                                                        element={<Imports />}
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "schools",
                                                        )}/*`}
                                                        element={<Schools />}
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "calculators",
                                                        )}/*`}
                                                        element={
                                                            <Calculators />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "writing-calculators",
                                                        )}/*`}
                                                        element={
                                                            <WritingCalculators />
                                                        }
                                                    />
                                                    <Route
                                                        path={route(
                                                            "dashboard",
                                                        )}
                                                        element={<Dashboard />}
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "users",
                                                        )}/*`}
                                                        element={<Users />}
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "devTasks",
                                                        )}/*`}
                                                        element={<DevTasks />}
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "grantAccess",
                                                        )}/*`}
                                                        element={
                                                            <GrantAccess />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${route(
                                                            "grantConsumption",
                                                        )}/*`}
                                                        element={
                                                            <GrantConsumption />
                                                        }
                                                    />
                                                </Routes>
                                            </User>
                                        </Screen>
                                    </BrowserRouter>
                                </Suspense>
                            </ErrorBoundary>
                        </ThemeProvider>
                    </AppState>
                </ApolloProvider>
            </HelmetProvider>
        </CacheProvider>
    );
}

export default App;
